.BudgetModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.BudgetModalContent_Chart {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.BudgetModal_Frequency {
  .cds--label {
    margin-bottom: 6px;
  }

  .BudgetModal_FrequencyOptions.cds--btn-set .cds--btn {
    min-height: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}