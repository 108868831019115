.PropertyModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PropertyModalContent_Chart {
  width: 100%;
  height: 200px;
  overflow: hidden;
}