@use '@carbon/react';

html,
body,
#root,
.App,
.App_Content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.App {
  .cds--side-nav {
    height: calc(100% - 48px);
  }

  .cds--side-nav__items {
    padding-top: 0;
    border-right: 1px solid #ccc;
  }

  .App_ActionButtons {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
}

.App_Content {
  position: absolute;
  padding: 48px 0 0 256px;
}

.ModalContent_TwoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.ModalContent_ThreeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}

.cds--table-toolbar {
  background-color: transparent;
  margin-top: -48px;
}

.PositiveColor {
  color: darkgreen;
}

.NegativeColor {
  color: darkred;
}

.cds--data-table-container {
  padding-top: 0;
  overflow: hidden;
}

.cds--data-table-header {
  padding-bottom: 1rem;
  max-width: 100%;
}

.cds--data-table-header__description {
  max-width: 100%;
}

.cds--data-table-content {
  max-height: calc(100% - 110px);
}

.cds--data-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.cds--data-table th[align='center'] div {
  text-align: center;
}

.cds--data-table th[align='right'] div {
  text-align: right;
}

.cds--header__global {
  .cds--dropdown__wrapper {
    width: 130px;
    display: flex;
    align-items: center;
  }
}

.cds--assistive-text {
  pointer-events: none !important;
}

.cds--btn--icon-only {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

header.cds--header {
  background-color: #393939;

  .Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
  }

  a.cds--header__menu-item:hover {
    color: white;
  }

  .cds--btn.cds--btn--icon-only.cds--header__action svg {
    fill: white;
  }
}

.App_ActionButtons {
  .cds--btn {
    width: 100%;
  }
}
