.LoanModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LoanModalContent_Chart {
  width: 100%;
  height: 250px;
  overflow: hidden;
}