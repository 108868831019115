.PropertyCostsModal {
  .cds--modal-content {
    margin-bottom: 0;
    overflow-x: hidden;
  }
}

.PropertyCostsModalContent {
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.PropertyCosts_ListHeader,
.PropertyCosts_ListRow {
  width: 100%;
  display: grid;
  grid-template-columns: auto 160px 150px 80px;
  grid-gap: 8px;
}

.PropertyCosts_ListActions {
  button {
    min-height: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    .cds--assistive-text {
      display: none !important; 
    }
  }
}

.PropertyCosts_FrequencyOptions.cds--btn-set {
  .cds--btn {
    min-height: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}