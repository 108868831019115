.InfoGroup {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 8px;
}

.InfoGroup_Item {
  display: flex;
  flex-direction: column;
}

.InfoGroup_Item_Label {
  font-size: 0.9em;
}

.InfoGroup_Item_Value {
  font-size: 1.5em;
}